$font-family: “Arial, Helvetica, sans-serif”;
$font-icon: “icomoon”;

$background-main: #edf1f5;
$gray-900: #252632;
$gray-800: #2f3239;
$gray-700: #6e6e6e;
$button-primary: #005ea0;
$goal-1: #30ccae;
$goal-2: #e5547e;
$goal-3: #a57ee0;
$progress-percentage: #010101;
$white: #fff;
$day-event-0: #b280fc;
$day-event-1: #22d100;
$day-event-2: #ffd800;
$cal-control-button: #e0e4f2;

/* Custom Colour Matrix
----------------------------------------------------- */

$theme-colors: (
  'background-main': $background-main,
  'gray-900': $gray-900,
  'gray-800': $gray-800,
  'gray-700': $gray-700,
  'button-primary': $button-primary,
  'goal-1': $goal-1,
  'goal-2': $goal-2,
  'goal-3': $goal-3,
  'progress-percentage': $progress-percentage,
  'white': $white,
  'day-event-0': $day-event-0,
  'day-event-1': $day-event-1,
  'day-event-2': $day-event-2,
  'cal-control-button': $cal-control-button
);

$primary-color: #00c5c1;
$background-color: #f0fafa;
$goal-colors: (
  'goal-1': $goal-1,
  'goal-2': $goal-2,
  'goal-3': $goal-3
);

$tab-colors: (
  'tab-1': $button-primary,
  'tab-2': $day-event-1,
  'tab-3': $day-event-2,
  'tab-4': $day-event-0,
  'tab-5': $goal-1
);
$grid-gutter-width: 30px !default;
