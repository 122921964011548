/* You can add global styles to this file, and also import other style files */
/*============================================================================================*/
/* 1. GENERAL STYLES / HELPERS
/*============================================================================================*/
@import './styles/variables';
@import './styles/utilities/mixins';
@import './styles/utilities/functions';
// @import "~/swiper/scss";
// @import "~swiper/scss/navigation";
// @import "~swiper/scss/pagination";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  &.workroom {
    /*background-image: url('./assets/bgWorkroom.png');
    background-repeat: no-repeat;
    background-position: left 74px;*/
    background: #568dd0;
  }
  background-color: #e3e7f2;
  font-family: Arial, Helvetica, sans-serif;
}

/* prettier-ignore */
@font-face {
  font-family: 'icomoon';
  src: url('./assets/fonts/icomoon.eot?p4rbob');
  src: url('./assets/fonts/icomoon.eot?p4rbob#iefix') format('embedded-opentype'),
  url('./assets/fonts/icomoon.woff2?p4rbob') format('woff2'),
  url('./assets/fonts/icomoon.ttf?p4rbob') format('truetype'),
  url('./assets/fonts/icomoon.woff?p4rbob') format('woff'),
  url('./assets/fonts/icomoon.svg?p4rbob#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
img{
  max-width: 100%;
}
.card {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  background: #fff;
  box-shadow: 4.086px 8.019px 16.83px 0.17px rgba(81, 92, 176, 0.4);
}
.form-heading {
  font-size: 32px;
  font-weight: bold;
}
.cols {
  width: 49%;
}
.ff-outer-container {
  display: block;
  border: 1px solid $primary-color;
  border-radius: 10px;
  margin-bottom: 10px;
  .ff-container {
    padding: 10px;
    border-radius: 10px;
    background-color: $background-color;
    label {
      display: block;
      color: $primary-color;
      font-size: 16px;
    }
    input {
      width: 100%;
      background-color: $background-color;
      border: none;
    }
    input:focus {
      outline: 0;
    }
  }
}
.ff-outer-container:focus-within {
  border: 3px solid $primary-color;
  outline: 0;
}
.disable {
  pointer-events: none;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-call_made:before {
  content: '\ebc6';
}

.icon-icon-progress-badge-0of10 .path1:before {
  content: '\eb51';
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-0of10 .path2:before {
  content: '\eb52';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-0of10 .path3:before {
  content: '\eb53';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-badge-0of10 .path4:before {
  content: '\eb54';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.05;
}

.icon-icon-progress-badge-0of10 .path5:before {
  content: '\eb55';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-0of10 .path6:before {
  content: '\eb56';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-badge-0of10 .path7:before {
  content: '\eb57';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-2of10 .path1:before {
  content: '\eb58';
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-2of10 .path2:before {
  content: '\eb59';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-2of10 .path3:before {
  content: '\eb5a';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-badge-2of10 .path4:before {
  content: '\eb5b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.05;
}

.icon-icon-progress-badge-2of10 .path5:before {
  content: '\eb5c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-2of10 .path6:before {
  content: '\eb5d';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-badge-2of10 .path7:before {
  content: '\eb5e';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-2of10 .path8:before {
  content: '\eb5f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-2of10 .path9:before {
  content: '\eb60';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path1:before {
  content: '\eb61';
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-6of10 .path2:before {
  content: '\eb62';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path3:before {
  content: '\eb63';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-badge-6of10 .path4:before {
  content: '\eb64';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.05;
}

.icon-icon-progress-badge-6of10 .path5:before {
  content: '\eb65';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-6of10 .path6:before {
  content: '\eb66';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-badge-6of10 .path7:before {
  content: '\eb67';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-6of10 .path8:before {
  content: '\eb68';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path9:before {
  content: '\eb69';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path10:before {
  content: '\eb6a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path11:before {
  content: '\eb6b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path12:before {
  content: '\eb6c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-6of10 .path13:before {
  content: '\eb6d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path1:before {
  content: '\eb6e';
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-10of10 .path2:before {
  content: '\eb6f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path3:before {
  content: '\eb70';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-badge-10of10 .path4:before {
  content: '\eb71';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.05;
}

.icon-icon-progress-badge-10of10 .path5:before {
  content: '\eb72';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-10of10 .path6:before {
  content: '\eb73';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-badge-10of10 .path7:before {
  content: '\eb74';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-badge-10of10 .path8:before {
  content: '\eb75';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path9:before {
  content: '\eb76';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path10:before {
  content: '\eb77';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path11:before {
  content: '\eb78';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path12:before {
  content: '\eb79';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path13:before {
  content: '\eb7a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path14:before {
  content: '\eb7b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path15:before {
  content: '\eb7c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path16:before {
  content: '\eb7d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-badge-10of10 .path17:before {
  content: '\eb7e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-0of10 .path1:before {
  content: '\eb7f';
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-trophy-0of10 .path2:before {
  content: '\eb80';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-0of10 .path3:before {
  content: '\eb81';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-0of10 .path4:before {
  content: '\eb82';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-0of10 .path5:before {
  content: '\eb83';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-trophy-0of10 .path6:before {
  content: '\eb84';
  margin-left: -1em;
  color: rgb(239, 183, 24);
}

.icon-icon-progress-trophy-0of10 .path7:before {
  content: '\eb85';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-0of10 .path8:before {
  content: '\eb86';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-0of10 .path9:before {
  content: '\eb87';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.9;
}

.icon-icon-progress-trophy-0of10 .path10:before {
  content: '\eb88';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-1of10 .path1:before {
  content: '\eb89';
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-trophy-1of10 .path2:before {
  content: '\eb8a';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-1of10 .path3:before {
  content: '\eb8b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-1of10 .path4:before {
  content: '\eb8c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-1of10 .path5:before {
  content: '\eb8d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-1of10 .path6:before {
  content: '\eb8e';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-trophy-1of10 .path7:before {
  content: '\eb8f';
  margin-left: -1em;
  color: rgb(239, 183, 24);
}

.icon-icon-progress-trophy-1of10 .path8:before {
  content: '\eb90';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-1of10 .path9:before {
  content: '\eb91';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-1of10 .path10:before {
  content: '\eb92';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.9;
}

.icon-icon-progress-trophy-1of10 .path11:before {
  content: '\eb93';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-6of10 .path1:before {
  content: '\eb94';
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-trophy-6of10 .path2:before {
  content: '\eb95';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-6of10 .path3:before {
  content: '\eb96';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path4:before {
  content: '\eb97';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path5:before {
  content: '\eb98';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path6:before {
  content: '\eb99';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path7:before {
  content: '\eb9a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path8:before {
  content: '\eb9b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path9:before {
  content: '\eb9c';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-6of10 .path10:before {
  content: '\eb9d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-6of10 .path11:before {
  content: '\eb9e';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-trophy-6of10 .path12:before {
  content: '\eb9f';
  margin-left: -1em;
  color: rgb(239, 183, 24);
}

.icon-icon-progress-trophy-6of10 .path13:before {
  content: '\eba0';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-6of10 .path14:before {
  content: '\eba1';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-6of10 .path15:before {
  content: '\eba2';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.9;
}

.icon-icon-progress-trophy-6of10 .path16:before {
  content: '\eba3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-10of10 .path1:before {
  content: '\eba4';
  color: rgb(255, 255, 255);
  opacity: 0.1;
}

.icon-icon-progress-trophy-10of10 .path2:before {
  content: '\eba5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-10of10 .path3:before {
  content: '\eba6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path4:before {
  content: '\eba7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path5:before {
  content: '\eba8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path6:before {
  content: '\eba9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path7:before {
  content: '\ebaa';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path8:before {
  content: '\ebab';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path9:before {
  content: '\ebac';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path10:before {
  content: '\ebad';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path11:before {
  content: '\ebae';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path12:before {
  content: '\ebaf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path13:before {
  content: '\ebb0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-progress-trophy-10of10 .path14:before {
  content: '\ebb1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-progress-trophy-10of10 .path15:before {
  content: '\ebb2';
  margin-left: -1em;
  color: rgb(242, 197, 31);
}

.icon-icon-progress-trophy-10of10 .path16:before {
  content: '\ebb3';
  margin-left: -1em;
  color: rgb(239, 183, 24);
}

.icon-icon-progress-trophy-10of10 .path17:before {
  content: '\ebb4';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-10of10 .path18:before {
  content: '\ebb5';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.39;
}

.icon-icon-progress-trophy-10of10 .path19:before {
  content: '\ebb6';
  margin-left: -1em;
  color: rgb(69, 65, 181);
  opacity: 0.9;
}

.icon-icon-progress-trophy-10of10 .path20:before {
  content: '\ebb7';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path1:before {
  content: '\ebb8';
  color: rgb(0, 189, 213);
}

.icon-icon-smart-goal .path2:before {
  content: '\ebb9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.05;
}

.icon-icon-smart-goal .path3:before {
  content: '\ebba';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.06;
}

.icon-icon-smart-goal .path4:before {
  content: '\ebbb';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-icon-smart-goal .path5:before {
  content: '\ebbc';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path6:before {
  content: '\ebbd';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path7:before {
  content: '\ebbe';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path8:before {
  content: '\ebbf';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path9:before {
  content: '\ebc0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path10:before {
  content: '\ebc1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path11:before {
  content: '\ebc2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path12:before {
  content: '\ebc3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-smart-goal .path13:before {
  content: '\ebc4';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-c-virtual-assist .path1:before {
  content: '\e901';
  color: rgb(0, 0, 0);
}

.icon-icon-c-virtual-assist .path2:before {
  content: '\e902';
  margin-left: -0.8369140625em;
  color: rgb(92, 199, 224);
}

.icon-icon-c-virtual-assist .path3:before {
  content: '\e903';
  margin-left: -0.8369140625em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-virtual-assist .path4:before {
  content: '\e907';
  margin-left: -0.8369140625em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-virtual-assist .path5:before {
  content: '\e90a';
  margin-left: -0.8369140625em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach .path1:before {
  content: '\e90c';
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path2:before {
  content: '\e915';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path3:before {
  content: '\e916';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path4:before {
  content: '\e919';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path5:before {
  content: '\e922';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path6:before {
  content: '\e923';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path7:before {
  content: '\e926';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path8:before {
  content: '\e927';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path9:before {
  content: '\e928';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path10:before {
  content: '\e929';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-c-my-coach .path11:before {
  content: '\e92a';
  margin-left: -0.943359375em;
  color: rgb(159, 130, 217);
}

.icon-icon-c-my-coach .path12:before {
  content: '\e92d';
  margin-left: -0.943359375em;
  color: rgb(214, 94, 127);
}

.icon-icon-c-my-coach .path13:before {
  content: '\e92e';
  margin-left: -0.943359375em;
  color: rgb(97, 198, 173);
}

.icon-icon-c-my-coach .path14:before {
  content: '\e92f';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach .path15:before {
  content: '\e931';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach .path16:before {
  content: '\e935';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach1 .path1:before {
  content: '\e93a';
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path2:before {
  content: '\e93f';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path3:before {
  content: '\e940';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path4:before {
  content: '\e945';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path5:before {
  content: '\e947';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path6:before {
  content: '\e948';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path7:before {
  content: '\e94a';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path8:before {
  content: '\e94b';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path9:before {
  content: '\e94c';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path10:before {
  content: '\e94d';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
  opacity: 0.1;
}

.icon-icon-c-my-coach1 .path11:before {
  content: '\e94e';
  margin-left: -0.943359375em;
  color: rgb(159, 130, 217);
}

.icon-icon-c-my-coach1 .path12:before {
  content: '\e94f';
  margin-left: -0.943359375em;
  color: rgb(214, 94, 127);
}

.icon-icon-c-my-coach1 .path13:before {
  content: '\e950';
  margin-left: -0.943359375em;
  color: rgb(97, 198, 173);
}

.icon-icon-c-my-coach1 .path14:before {
  content: '\e951';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach1 .path15:before {
  content: '\e952';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-c-my-coach1 .path16:before {
  content: '\e953';
  margin-left: -0.943359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-my-collection .path1:before {
  content: '\e954';
  color: rgb(0, 0, 0);
}

.icon-icon-fc-my-collection .path2:before {
  content: '\e955';
  margin-left: -1.4453125em;
  color: rgb(58, 148, 205);
}

.icon-icon-fc-my-collection .path3:before {
  content: '\e956';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path4:before {
  content: '\e957';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path5:before {
  content: '\e958';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path6:before {
  content: '\e959';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path7:before {
  content: '\e95a';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path8:before {
  content: '\e95b';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path9:before {
  content: '\e95c';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path10:before {
  content: '\e95d';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path11:before {
  content: '\e95e';
  margin-left: -1.4453125em;
  color: rgb(255, 53, 121);
}

.icon-icon-fc-my-collection .path12:before {
  content: '\e95f';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path13:before {
  content: '\e960';
  margin-left: -1.4453125em;
  color: rgb(46, 171, 224);
  opacity: 0.5;
}

.icon-icon-fc-my-collection .path14:before {
  content: '\e961';
  margin-left: -1.4453125em;
  color: rgb(46, 171, 224);
}

.icon-icon-fc-my-collection .path15:before {
  content: '\e962';
  margin-left: -1.4453125em;
  color: rgb(46, 171, 224);
}

.icon-icon-fc-my-collection .path16:before {
  content: '\e963';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path17:before {
  content: '\e964';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path18:before {
  content: '\e965';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path19:before {
  content: '\e966';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path20:before {
  content: '\e967';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path21:before {
  content: '\e968';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path22:before {
  content: '\e969';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path23:before {
  content: '\e96a';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path24:before {
  content: '\e96c';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path25:before {
  content: '\e96e';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path26:before {
  content: '\e96f';
  margin-left: -1.4453125em;
  color: rgb(136, 180, 64);
}

.icon-icon-fc-my-collection .path27:before {
  content: '\e970';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path28:before {
  content: '\e971';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path29:before {
  content: '\e972';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path30:before {
  content: '\e973';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path31:before {
  content: '\e974';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path32:before {
  content: '\e975';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path33:before {
  content: '\e976';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path34:before {
  content: '\e977';
  margin-left: -1.4453125em;
  color: rgb(133, 91, 249);
}

.icon-icon-fc-my-collection .path35:before {
  content: '\e978';
  margin-left: -1.4453125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-my-collection .path36:before {
  content: '\e979';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path37:before {
  content: '\e97a';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path38:before {
  content: '\e97b';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path39:before {
  content: '\e97c';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path40:before {
  content: '\e97d';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-my-collection .path41:before {
  content: '\e97e';
  margin-left: -1.4453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-the-lounge .path1:before {
  content: '\e97f';
  color: rgb(255, 255, 255);
}

.icon-icon-the-lounge .path2:before {
  content: '\e980';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path3:before {
  content: '\e981';
  margin-left: -0.53125em;
  color: rgb(255, 255, 255);
}

.icon-icon-the-lounge .path4:before {
  content: '\e982';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path5:before {
  content: '\e983';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path6:before {
  content: '\e984';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path7:before {
  content: '\e985';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path8:before {
  content: '\e986';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-the-lounge .path9:before {
  content: '\e987';
  margin-left: -0.53125em;
  color: rgb(0, 0, 0);
}

.icon-icon-working-small:before {
  content: '\e988';
}

.icon-icon-working:before {
  content: '\e989';
}

.icon-icon-report-simpler .path1:before {
  content: '\e98a';
  color: rgb(255, 255, 255);
}

.icon-icon-report-simpler .path2:before {
  content: '\e98b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path3:before {
  content: '\e98c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path4:before {
  content: '\e98d';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path5:before {
  content: '\e98e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path6:before {
  content: '\e98f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path7:before {
  content: '\e990';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report-simpler .path8:before {
  content: '\e991';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-report:before {
  content: '\e992';
}

.icon-icon-c-find-a-coach .path1:before {
  content: '\e993';
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path2:before {
  content: '\e994';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path3:before {
  content: '\e995';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path4:before {
  content: '\e996';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path5:before {
  content: '\e997';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path6:before {
  content: '\e998';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path7:before {
  content: '\e999';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path8:before {
  content: '\e99a';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path9:before {
  content: '\e99b';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path10:before {
  content: '\e99c';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path11:before {
  content: '\e99d';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path12:before {
  content: '\e99e';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path13:before {
  content: '\e99f';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path14:before {
  content: '\e9a0';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path15:before {
  content: '\e9a1';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path16:before {
  content: '\e9a2';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path17:before {
  content: '\e9a3';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path18:before {
  content: '\e9a4';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path19:before {
  content: '\e9a5';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-c-find-a-coach .path20:before {
  content: '\e9a6';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path21:before {
  content: '\e9a7';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path22:before {
  content: '\e9a8';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path23:before {
  content: '\e9a9';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path24:before {
  content: '\e9aa';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path25:before {
  content: '\e9ab';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path26:before {
  content: '\e9ac';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path27:before {
  content: '\e9ad';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-c-find-a-coach .path28:before {
  content: '\e9ae';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path29:before {
  content: '\e9af';
  margin-left: -1em;
  color: rgb(21, 163, 133);
}

.icon-icon-c-find-a-coach .path30:before {
  content: '\e9b0';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-c-find-a-coach .path31:before {
  content: '\e9b1';
  margin-left: -1em;
  color: rgb(214, 242, 213);
}

.icon-icon-c-find-a-coach .path32:before {
  content: '\e9b2';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-c-find-a-coach .path33:before {
  content: '\e9b3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path1:before {
  content: '\e9b4';
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path2:before {
  content: '\e9b5';
  margin-left: -1.658203125em;
  color: rgb(244, 198, 3);
}

.icon-icon-fc-notes .path3:before {
  content: '\e9b6';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path4:before {
  content: '\e9b7';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path5:before {
  content: '\e9b8';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path6:before {
  content: '\e9b9';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path7:before {
  content: '\e9ba';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path8:before {
  content: '\e9bb';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path9:before {
  content: '\e9bc';
  margin-left: -1.658203125em;
  color: rgb(255, 255, 255);
}

.icon-icon-fc-notes .path10:before {
  content: '\e9bd';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path11:before {
  content: '\e9be';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path12:before {
  content: '\e9bf';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path13:before {
  content: '\e9c0';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}

.icon-icon-fc-notes .path14:before {
  content: '\e9c1';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path15:before {
  content: '\e9c2';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-notes .path16:before {
  content: '\e9c3';
  margin-left: -1.658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fc-smart-goals .path1:before {
  content: '\e9c4';
  color: rgb(255, 255, 255);
}

.icon-icon-fc-smart-goals .path2:before {
  content: '\e9c5';
  margin-left: -1em;
  color: rgb(97, 198, 173);
}

.icon-icon-fc-smart-goals .path3:before {
  content: '\e9c6';
  margin-left: -1em;
  color: rgb(214, 94, 127);
}

.icon-icon-fc-smart-goals .path4:before {
  content: '\e9c7';
  margin-left: -1em;
  color: rgb(159, 130, 217);
}

.icon-icon-fc-smart-goals .path5:before {
  content: '\e9c8';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}

.icon-icon-fc-smart-goals .path6:before {
  content: '\e9c9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path1:before {
  content: '\e9ca';
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path2:before {
  content: '\e9cb';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path3:before {
  content: '\e9cc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path4:before {
  content: '\e9cd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path5:before {
  content: '\e9ce';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path6:before {
  content: '\e9cf';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path7:before {
  content: '\e9d0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path8:before {
  content: '\e9d1';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path9:before {
  content: '\e9d2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path10:before {
  content: '\e9d3';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path11:before {
  content: '\e9d4';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path12:before {
  content: '\e9d5';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path13:before {
  content: '\e9d6';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path14:before {
  content: '\e9d7';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path15:before {
  content: '\e9d8';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path16:before {
  content: '\e9d9';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path17:before {
  content: '\e9da';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path18:before {
  content: '\e9db';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path19:before {
  content: '\e9dc';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path20:before {
  content: '\e9dd';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path21:before {
  content: '\e9de';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path22:before {
  content: '\e9df';
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.8;
}

.icon-icon-user-profile .path23:before {
  content: '\e9e0';
  margin-left: -1em;
  color: rgb(92, 199, 224);
}

.icon-icon-user-profile .path24:before {
  content: '\e9e1';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-user-profile .path25:before {
  content: '\e9e2';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-user-profile .path26:before {
  content: '\e9e3';
  margin-left: -1em;
  color: rgb(255, 255, 255);
}

.icon-icon-nudge-transparent-arrows-bkg:before {
  content: '\e9e4';
  color: #fff;
}

.icon-icon-nudge-solid-insidehand .path1:before {
  content: '\e9e5';
  color: rgb(255, 255, 255);
}

.icon-icon-nudge-solid-insidehand .path2:before {
  content: '\e9e6';
  margin-left: -0.7548828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-give-kudos .path1:before {
  content: '\e9e7';
  color: rgb(51, 153, 153);
}

.icon-icon-give-kudos .path2:before {
  content: '\e9e8';
  margin-left: -1.1484375em;
  color: rgb(51, 153, 153);
}

.icon-icon-give-kudos .path3:before {
  content: '\e9e9';
  margin-left: -1.1484375em;
  color: rgb(139, 128, 223);
}

.icon-icon-give-kudos .path4:before {
  content: '\e9ea';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path5:before {
  content: '\e9eb';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path6:before {
  content: '\e9ec';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path7:before {
  content: '\e9ed';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path8:before {
  content: '\e9ee';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path9:before {
  content: '\e9ef';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path10:before {
  content: '\e9f0';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path11:before {
  content: '\e9f1';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-give-kudos .path12:before {
  content: '\e9f2';
  margin-left: -1.1484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-create-goal-action-steps .path1:before {
  content: '\e9f3';
  opacity: 0.5;
}

.icon-icon-create-goal-action-steps .path2:before {
  content: '\e9f4';
  margin-left: -1.0859375em;
  opacity: 0.8;
}

.icon-icon-create-goal-action-steps .path3:before {
  content: '\e9f5';
  margin-left: -1.0859375em;
}

.icon-icon-bubble-3dots:before {
  content: '\e9f6';
}

.icon-icon-bubble-3lines:before {
  content: '\e9f7';
}

.icon-icon-bubble-chat:before {
  content: '\e9f8';
}

.icon-icon-create-goal-indicator-focus .path1:before {
  content: '\e9f9';
  color: rgb(255, 255, 255);
}

.icon-icon-create-goal-indicator-focus .path2:before {
  content: '\e9fa';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path3:before {
  content: '\e9fb';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path4:before {
  content: '\e9fc';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path5:before {
  content: '\e9fd';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path6:before {
  content: '\e9fe';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path7:before {
  content: '\e9ff';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path8:before {
  content: '\ea00';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path9:before {
  content: '\ea01';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path10:before {
  content: '\ea02';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path11:before {
  content: '\ea03';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-indicator-focus .path12:before {
  content: '\ea04';
  margin-left: -0.9111328125em;
}

.icon-icon-create-goal-assessment:before {
  content: '\ea05';
}

.icon-icon-create-goal-areas-for-growth .path1:before {
  content: '\ea06';
}

.icon-icon-create-goal-areas-for-growth .path2:before {
  content: '\ea07';
  margin-left: -0.5732421875em;
  color: rgb(255, 255, 255);
}

.icon-icon-create-goal-areas-for-growth .path3:before {
  content: '\ea08';
  margin-left: -0.5732421875em;
  color: rgb(255, 255, 255);
}

.icon-icon-create-goal-areas-for-growth .path4:before {
  content: '\ea09';
  margin-left: -0.5732421875em;
}

.icon-icon-create-goal-areas-for-growth .path5:before {
  content: '\ea0a';
  margin-left: -0.5732421875em;
}

.icon-icon-create-goal-areas-for-growth .path6:before {
  content: '\ea0b';
  margin-left: -0.5732421875em;
}

.icon-icon-create-goal-areas-for-growth .path7:before {
  content: '\ea0c';
  margin-left: -0.5732421875em;
}

.icon-icon-bubble-respond:before {
  content: '\ea0d';
}

.icon-icon-file-video:before {
  content: '\ea0e';
}

.icon-icon-play:before {
  content: '\ea0f';
}

.icon-icon-stickynote-insert-options-list .path1:before {
  content: '\ea10';
  color: rgb(217, 217, 217);
}

.icon-icon-stickynote-insert-options-list .path2:before {
  content: '\ea11';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-list .path3:before {
  content: '\ea12';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-list .path4:before {
  content: '\ea13';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-list .path5:before {
  content: '\ea14';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-list .path6:before {
  content: '\ea15';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-list .path7:before {
  content: '\ea16';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-checkbox .path1:before {
  content: '\ea17';
  color: rgb(217, 217, 217);
}

.icon-icon-stickynote-insert-options-checkbox .path2:before {
  content: '\ea18';
  margin-left: -0.9658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-checkbox .path3:before {
  content: '\ea19';
  margin-left: -0.9658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-checkbox .path4:before {
  content: '\ea1a';
  margin-left: -0.9658203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-bullet .path1:before {
  content: '\ea1b';
  color: rgb(217, 217, 217);
}

.icon-icon-stickynote-insert-options-bullet .path2:before {
  content: '\ea1c';
  margin-left: -0.9638671875em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-bullet .path3:before {
  content: '\ea1d';
  margin-left: -0.9638671875em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-bullet .path4:before {
  content: '\ea1e';
  margin-left: -0.9638671875em;
  color: rgb(0, 0, 0);
}

.icon-icon-drag-n-drop .path1:before {
  content: '\ea1f';
  opacity: 0.18;
}

.icon-icon-drag-n-drop .path2:before {
  content: '\ea20';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path3:before {
  content: '\ea21';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path4:before {
  content: '\ea22';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path5:before {
  content: '\ea23';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path6:before {
  content: '\ea24';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path7:before {
  content: '\ea25';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path8:before {
  content: '\ea26';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path9:before {
  content: '\ea27';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path10:before {
  content: '\ea28';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path11:before {
  content: '\ea29';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path12:before {
  content: '\ea2a';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path13:before {
  content: '\ea2b';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path14:before {
  content: '\ea2c';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path15:before {
  content: '\ea2d';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path16:before {
  content: '\ea2e';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path17:before {
  content: '\ea2f';
  margin-left: -1.0869140625em;
}

.icon-icon-drag-n-drop .path18:before {
  content: '\ea30';
  margin-left: -1.0869140625em;
}

.icon-icon-half-smartgoal-seal .path1:before {
  content: '\ea31';
  color: rgb(0, 189, 213);
}

.icon-icon-half-smartgoal-seal .path2:before {
  content: '\ea32';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-icon-half-smartgoal-seal .path3:before {
  content: '\ea33';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path4:before {
  content: '\ea34';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path5:before {
  content: '\ea35';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path6:before {
  content: '\ea36';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path7:before {
  content: '\ea37';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path8:before {
  content: '\ea38';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path9:before {
  content: '\ea39';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path10:before {
  content: '\ea3a';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-half-smartgoal-seal .path11:before {
  content: '\ea3b';
  margin-left: -1.76953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-in-progress-circle:before {
  content: '\ea3c';
}

.icon-icon-coach-rating .path1:before {
  content: '\ea3d';
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.icon-icon-coach-rating .path2:before {
  content: '\ea3e';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.icon-icon-coach-rating .path3:before {
  content: '\ea3f';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.icon-icon-coach-rating .path4:before {
  content: '\ea41';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
  opacity: 0.9;
}

.icon-icon-coach-rating .path5:before {
  content: '\ea42';
  margin-left: -0.7314453125em;
  color: rgb(243, 183, 36);
}

.icon-icon-coach-rating .path6:before {
  content: '\ea43';
  margin-left: -0.7314453125em;
  color: rgb(166, 97, 38);
}

.icon-icon-coach-rating .path7:before {
  content: '\ea44';
  margin-left: -0.7314453125em;
  color: rgb(166, 97, 38);
}

.icon-icon-coach-rating .path8:before {
  content: '\ea45';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-coach-rating .path9:before {
  content: '\ea46';
  margin-left: -0.7314453125em;
  color: rgb(243, 183, 36);
  opacity: 0.4;
}

.icon-icon-coach-rating .path10:before {
  content: '\ea47';
  margin-left: -0.7314453125em;
  color: rgb(243, 183, 36);
}

.icon-icon-coach-rating .path11:before {
  content: '\ea48';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}

.icon-icon-coach-rating .path12:before {
  content: '\ea49';
  margin-left: -0.7314453125em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path1:before {
  content: '\ea4a';
  color: rgb(73, 151, 170);
}

.icon-icon-calendar-checks .path2:before {
  content: '\ea4b';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path3:before {
  content: '\ea4c';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path4:before {
  content: '\ea4d';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path5:before {
  content: '\ea4e';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path6:before {
  content: '\ea4f';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path7:before {
  content: '\ea50';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path8:before {
  content: '\ea51';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path9:before {
  content: '\ea52';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path10:before {
  content: '\ea53';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path11:before {
  content: '\ea54';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path12:before {
  content: '\ea55';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path13:before {
  content: '\ea56';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path14:before {
  content: '\ea57';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path15:before {
  content: '\ea58';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path16:before {
  content: '\ea59';
  margin-left: -1.1259765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-calendar-checks .path17:before {
  content: '\ea5a';
  margin-left: -1.1259765625em;
  color: rgb(73, 151, 170);
}

.icon-icon-calendar:before {
  content: '\ea5b';
}

.icon-icon-3-sticky-notes:before {
  content: '\ea5c';
}

.icon-icon-time-spent-with-coach:before {
  content: '\ea5d';
}

.icon-icon-airplane:before {
  content: '\ea5e';
}

.icon-icon-make-bold:before {
  content: '\ea5f';
}

.icon-icon-choose-color .path1:before {
  content: '\ea60';
  color: rgb(0, 0, 0);
}

.icon-icon-choose-color .path2:before {
  content: '\ea61';
  margin-left: -1.126953125em;
  color: rgb(255, 255, 255);
}

.icon-icon-choose-color .path3:before {
  content: '\ea62';
  margin-left: -1.126953125em;
  color: rgb(53, 53, 53);
}

.icon-icon-remove-from-pln .path1:before {
  content: '\ea63';
  color: rgb(255, 255, 255);
}

.icon-icon-remove-from-pln .path2:before {
  content: '\ea64';
  margin-left: -2.109375em;
  color: rgb(255, 255, 255);
}

.icon-icon-remove-from-pln .path3:before {
  content: '\ea65';
  margin-left: -2.109375em;
  color: rgb(255, 255, 255);
}

.icon-icon-remove-from-pln .path4:before {
  content: '\ea66';
  margin-left: -2.109375em;
  color: rgb(255, 255, 255);
}

.icon-icon-remove-from-pln .path5:before {
  content: '\ea67';
  margin-left: -2.109375em;
  color: rgb(255, 255, 255);
}

.icon-icon-remove-from-pln .path6:before {
  content: '\ea68';
  margin-left: -2.109375em;
  color: rgb(0, 0, 0);
}

.icon-icon-view-their-profile:before {
  content: '\ea69';
}

.icon-icon-remove-from-profile .path1:before {
  content: '\ea6a';
  color: rgb(0, 0, 0);
  opacity: 0.18;
}

.icon-icon-remove-from-profile .path2:before {
  content: '\ea6b';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path3:before {
  content: '\ea6c';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path4:before {
  content: '\ea6d';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path5:before {
  content: '\ea6e';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path6:before {
  content: '\ea6f';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path7:before {
  content: '\ea70';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path8:before {
  content: '\ea71';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path9:before {
  content: '\ea72';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path10:before {
  content: '\ea73';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path11:before {
  content: '\ea74';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path12:before {
  content: '\ea75';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path13:before {
  content: '\ea76';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path14:before {
  content: '\ea77';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path15:before {
  content: '\ea78';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path16:before {
  content: '\ea79';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path17:before {
  content: '\ea7a';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path18:before {
  content: '\ea7b';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path19:before {
  content: '\ea7c';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path20:before {
  content: '\ea7d';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path21:before {
  content: '\ea7e';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path22:before {
  content: '\ea7f';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path23:before {
  content: '\ea80';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path24:before {
  content: '\ea81';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path25:before {
  content: '\ea83';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-remove-from-profile .path26:before {
  content: '\ea84';
  margin-left: -1.205078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-see-reviews .path1:before {
  content: '\ea85';
  color: rgb(255, 255, 255);
}

.icon-icon-stars-see-reviews .path2:before {
  content: '\ea86';
  margin-left: -2.9609375em;
  color: rgb(255, 255, 255);
}

.icon-icon-stars-see-reviews .path3:before {
  content: '\ea87';
  margin-left: -2.9609375em;
  color: rgb(255, 255, 255);
}

.icon-icon-stars-see-reviews .path4:before {
  content: '\ea88';
  margin-left: -2.9609375em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-see-reviews .path5:before {
  content: '\ea89';
  margin-left: -2.9609375em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-see-reviews .path6:before {
  content: '\ea8a';
  margin-left: -2.9609375em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-see-reviews .path7:before {
  content: '\ea8b';
  margin-left: -2.9609375em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-solid:before {
  content: '\ea8c';
}

.icon-icon-stars-stroked .path1:before {
  content: '\ea8d';
  color: rgb(255, 255, 255);
}

.icon-icon-stars-stroked .path2:before {
  content: '\ea8e';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-stroked-transparent:before {
  content: '\ea8f';
}

.icon-icon-stars-half-vert .path1:before {
  content: '\ea90';
  color: rgb(255, 255, 255);
}

.icon-icon-stars-half-vert .path2:before {
  content: '\ea91';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-half-vert .path3:before {
  content: '\ea92';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-half-horiz .path1:before {
  content: '\ea93';
  color: rgb(255, 255, 255);
}

.icon-icon-stars-half-horiz .path2:before {
  content: '\ea94';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-half-horiz .path3:before {
  content: '\ea95';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-recommends-circled .path1:before {
  content: '\ea96';
  color: rgb(255, 255, 255);
  opacity: 0.3;
}

.icon-icon-recommends-circled .path2:before {
  content: '\ea97';
  margin-left: -1.396484375em;
  color: rgb(255, 255, 255);
}

.icon-icon-recommends-circled .path3:before {
  content: '\ea98';
  margin-left: -1.396484375em;
  color: rgb(0, 0, 0);
}

.icon-icon-recommends-circled .path4:before {
  content: '\ea99';
  margin-left: -1.396484375em;
  color: rgb(0, 0, 0);
}

.icon-icon-recommends-circled .path5:before {
  content: '\ea9a';
  margin-left: -1.396484375em;
  color: rgb(0, 0, 0);
}

.icon-icon-recommends-circled .path6:before {
  content: '\ea9b';
  margin-left: -1.396484375em;
  color: rgb(0, 0, 0);
}

.icon-icon-recommends:before {
  content: '\ea9c';
}

.icon-icon-make-a-sticky-note:before {
  content: '\ea9d';
}

.icon-icon-pin-active .path1:before {
  content: '\ea9e';
  color: rgb(0, 0, 0);
}

.icon-icon-pin-active .path2:before {
  content: '\ea9f';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-pin-active .path3:before {
  content: '\eaa0';
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-video-call:before {
  content: '\eaa1';
}

.icon-icon-category-video .path1:before {
  content: '\eaa2';
  color: rgb(46, 171, 224);
  opacity: 0.5;
}

.icon-icon-category-video .path2:before {
  content: '\eaa3';
  margin-left: -1.2705078125em;
  color: rgb(46, 171, 224);
}

.icon-icon-category-video .path3:before {
  content: '\eaa4';
  margin-left: -1.2705078125em;
  color: rgb(46, 171, 224);
}

.icon-icon-category-writing:before {
  content: '\eaa5';
  color: #ff3579;
}

.icon-icon-category-document:before {
  content: '\eaa6';
  color: #855bf9;
}

.icon-icon-category-template:before {
  content: '\eaa7';
  color: #88b440;
}

.icon-icon-arrows-move:before {
  content: '\eaa8';
}

.icon-icon-folders-outlined-reorganize:before {
  content: '\eaa9';
}

.icon-icon-folder-outlined-plus .path1:before {
  content: '\eaaa';
  color: rgb(0, 0, 0);
}

.icon-icon-folder-outlined-plus .path2:before {
  content: '\eaab';
  margin-left: -1.2705078125em;
  color: rgb(0, 0, 0);
}

.icon-icon-folder-outlined-plus .path3:before {
  content: '\eaac';
  margin-left: -1.2705078125em;
  color: rgb(32, 34, 29);
}

.icon-icon-folder-outlined-blank:before {
  content: '\eaad';
}

.icon-icon-folder-blank:before {
  content: '\eaae';
}

.icon-icon-folder-plus:before {
  content: '\eaaf';
}

.icon-icon-audio-file:before {
  content: '\eab0';
}

.icon-icon-website .path1:before {
  content: '\eab1';
  color: rgb(0, 0, 0);
  opacity: 0.7;
}

.icon-icon-website .path2:before {
  content: '\eab2';
  margin-left: -1.18310546875em;
  color: rgb(0, 0, 0);
  opacity: 0.7;
}

.icon-icon-website .path3:before {
  content: '\eab3';
  margin-left: -1.18310546875em;
  color: rgb(0, 0, 0);
}

.icon-icon-website .path4:before {
  content: '\eab4';
  margin-left: -1.18310546875em;
  color: rgb(0, 0, 0);
}

.icon-icon-website .path5:before {
  content: '\eab5';
  margin-left: -1.18310546875em;
  color: rgb(0, 0, 0);
}

.icon-icon-lock-closed:before {
  content: '\eab6';
}

.icon-icon-notification-exclaimation:before {
  content: '\eab7';
  color: #f02;
}

.icon-icon-notification-blank:before {
  content: '\eab8';
  color: #f02;
}

.icon-icon-nudge-stroke:before {
  content: '\eab9';
}

.icon-icon-nudge-solid-colored .path1:before {
  content: '\eaba';
  color: rgb(255, 255, 255);
}

.icon-icon-nudge-solid-colored .path2:before {
  content: '\eabb';
  margin-left: -1.3544921875em;
  color: rgb(0, 0, 0);
}

.icon-icon-list-view:before {
  content: '\eabc';
}

.icon-icon-grid-view:before {
  content: '\eabd';
}

.icon-icon-download:before {
  content: '\eabe';
}

.icon-icon-pencil:before {
  content: '\eabf';
}

.icon-icon-eye:before {
  content: '\eac0';
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path1:before {
  content: '\eac1';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path2:before {
  content: '\eac2';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path3:before {
  content: '\eac3';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
  opacity: 0.15;
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path4:before {
  content: '\eac4';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path5:before {
  content: '\eac5';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path6:before {
  content: '\eac6';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path7:before {
  content: '\eac7';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path8:before {
  content: '\eac8';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path9:before {
  content: '\eac9';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path10:before {
  content: '\eaca';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path11:before {
  content: '\eacb';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path12:before {
  content: '\eacc';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path13:before {
  content: '\eacd';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path14:before {
  content: '\eace';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path15:before {
  content: '\eacf';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path16:before {
  content: '\ead0';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit .path17:before {
  content: '\ead1';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path1:before {
  content: '\ead2';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path2:before {
  content: '\ead3';
  margin-left: -0.9970703125em;
  color: rgb(48, 204, 174);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path3:before {
  content: '\ead4';
  margin-left: -0.9970703125em;
  color: rgb(48, 204, 174);
  opacity: 0.15;
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path4:before {
  content: '\ead5';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path5:before {
  content: '\ead6';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path6:before {
  content: '\ead7';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path7:before {
  content: '\ead8';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path8:before {
  content: '\ead9';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path9:before {
  content: '\eada';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path10:before {
  content: '\eadb';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path11:before {
  content: '\eadc';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path12:before {
  content: '\eadd';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path13:before {
  content: '\eade';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path14:before {
  content: '\eae0';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path15:before {
  content: '\eae3';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path16:before {
  content: '\eae4';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE-checkit-colored .path17:before {
  content: '\eae5';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-fileit:before {
  content: '\eae6';
}

.icon-icon-fileit-check .path1:before {
  content: '\eae7';
  color: rgb(48, 204, 174);
}

.icon-icon-fileit-check .path2:before {
  content: '\eae8';
  margin-left: -1.1318359375em;
  color: rgb(48, 204, 174);
}

.icon-icon-fileit-check .path3:before {
  content: '\eae9';
  margin-left: -1.1318359375em;
  color: rgb(48, 204, 174);
}

.icon-icon-fileit-check .path4:before {
  content: '\eaea';
  margin-left: -1.1318359375em;
  color: rgb(48, 204, 174);
}

.icon-icon-fileit-check .path5:before {
  content: '\eaeb';
  margin-left: -1.1318359375em;
  color: rgb(48, 204, 174);
}

.icon-icon-fileit-check .path6:before {
  content: '\eaec';
  margin-left: -1.1318359375em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-book-transparent:before {
  content: '\eaed';
}

.icon-icon-readit-book:before {
  content: '\eaee';
}

.icon-icon-readit-unhelpful .path1:before {
  content: '\eaef';
  color: rgb(0, 0, 0);
}

.icon-icon-readit-unhelpful .path2:before {
  content: '\eaf0';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-unhelpful .path3:before {
  content: '\eaf1';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-unhelpful .path4:before {
  content: '\eaf2';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-unhelpful .path5:before {
  content: '\eaf3';
  margin-left: -1.3828125em;
  color: rgb(187, 42, 44);
}

.icon-icon-readit-meh .path1:before {
  content: '\eaf4';
  color: rgb(0, 0, 0);
}

.icon-icon-readit-meh .path2:before {
  content: '\eaf5';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-meh .path3:before {
  content: '\eaf6';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-meh .path4:before {
  content: '\eaf7';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-meh .path5:before {
  content: '\eaf8';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-meh .path6:before {
  content: '\eaf9';
  margin-left: -1.3828125em;
  color: rgb(255, 90, 0);
}

.icon-icon-readit-good .path1:before {
  content: '\eafa';
  color: rgb(0, 0, 0);
}

.icon-icon-readit-good .path2:before {
  content: '\eafb';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-good .path3:before {
  content: '\eafc';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-good .path4:before {
  content: '\eafd';
  margin-left: -1.3828125em;
  color: rgb(0, 0, 0);
}

.icon-icon-readit-good .path5:before {
  content: '\eafe';
  margin-left: -1.3828125em;
  color: rgb(104, 210, 0);
}

.icon-icon-assignment-todo .path1:before {
  content: '\eaff';
  color: rgb(0, 0, 0);
}

.icon-icon-assignment-todo .path2:before {
  content: '\eb00';
  margin-left: -0.43359375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-todo .path3:before {
  content: '\eb01';
  margin-left: -0.43359375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-todo .path4:before {
  content: '\eb02';
  margin-left: -0.43359375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-todo .path5:before {
  content: '\eb03';
  margin-left: -0.43359375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-done .path1:before {
  content: '\eb04';
  color: rgb(125, 99, 239);
}

.icon-icon-assignment-done .path2:before {
  content: '\eb05';
  margin-left: -0.65234375em;
  color: rgb(0, 0, 0);
}

.icon-icon-assignment-done .path3:before {
  content: '\eb06';
  margin-left: -0.65234375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-done .path4:before {
  content: '\eb07';
  margin-left: -0.65234375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-done .path5:before {
  content: '\eb08';
  margin-left: -0.65234375em;
  color: rgb(255, 255, 255);
}

.icon-icon-assignment-done .path6:before {
  content: '\eb09';
  margin-left: -0.65234375em;
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path1:before {
  content: '\eb0a';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path2:before {
  content: '\eb0b';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path3:before {
  content: '\eb0c';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
  opacity: 0.17;
}

.icon-icon-circlecheck-big-solidoutline-DONE .path4:before {
  content: '\eb0d';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path5:before {
  content: '\eb0e';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path6:before {
  content: '\eb0f';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path7:before {
  content: '\eb10';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solidoutline-DONE .path8:before {
  content: '\eb11';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-checkmark:before {
  content: '\eb12';
}

.icon-icon-circlecheck-small-inside-solid .path1:before {
  content: '\eb13';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-small-inside-solid .path2:before {
  content: '\eb14';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-small-inside-outline .path1:before {
  content: '\eb15';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-small-inside-outline .path2:before {
  content: '\eb16';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-outline .path1:before {
  content: '\eb17';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-outline .path2:before {
  content: '\eb18';
  margin-left: -0.9970703125em;
  color: rgb(0, 0, 0);
}

.icon-icon-circlecheck-big-solid .path1:before {
  content: '\eb19';
  color: rgb(255, 255, 255);
}

.icon-icon-circlecheck-big-solid .path2:before {
  content: '\eb1a';
  margin-left: -0.9970703125em;
}

.icon-icon-calendar-check .path1:before {
  content: '\eb1b';
}

.icon-icon-calendar-check .path2:before {
  content: '\eb1c';
  margin-left: -1.0263671875em;
  opacity: 0.5;
}

.icon-icon-calendar-check .path3:before {
  content: '\eb1d';
  margin-left: -1.0263671875em;
  opacity: 0.5;
}

.icon-icon-calendar-taskcalendar .path1:before {
  content: '\eb1e';
  color: rgb(0, 0, 0);
}

.icon-icon-calendar-taskcalendar .path2:before {
  content: '\eb1f';
  margin-left: -1em;
  color: rgb(81, 181, 162);
}

.icon-icon-calendar-taskcalendar .path3:before {
  content: '\eb20';
  margin-left: -1em;
  color: rgb(206, 73, 111);
}

.icon-icon-trash-open .path1:before {
  content: '\eb21';
  color: rgb(0, 0, 0);
}

.icon-icon-trash-open .path2:before {
  content: '\eb22';
  margin-left: -0.7509765625em;
  color: rgb(255, 255, 255);
}

.icon-icon-trash-open .path3:before {
  content: '\eb23';
  margin-left: -0.7509765625em;
  color: rgb(0, 0, 0);
}

.icon-icon-trash-closed:before {
  content: '\eb24';
}

.icon-icon-bubble-plus:before {
  content: '\eb25';
}

.icon-icon-folder-space-availability-folder:before {
  content: '\eb26';
  color: #ffe6aa;
}

.icon-icon-folder-space-availability-shadow .path1:before {
  content: '\eb27';
  color: rgb(0, 0, 0);
  opacity: 0.2;
}

.icon-icon-folder-space-availability-shadow .path2:before {
  content: '\eb28';
  margin-left: -1.490234375em;
  color: rgb(255, 255, 255);
}

.icon-icon-upload .path1:before {
  content: '\eb29';
  color: rgb(46, 171, 224);
}

.icon-icon-upload .path2:before {
  content: '\eb2a';
  margin-left: -1.267578125em;
  color: rgb(46, 171, 224);
}

.icon-icon-upload .path3:before {
  content: '\eb2b';
  margin-left: -1.267578125em;
  color: rgb(46, 171, 224);
}

.icon-icon-upload .path4:before {
  content: '\eb2c';
  margin-left: -1.267578125em;
  color: rgb(46, 171, 224);
}

.icon-icon-upload .path5:before {
  content: '\eb2d';
  margin-left: -1.267578125em;
  color: rgb(46, 171, 224);
}

.icon-icon-upload .path6:before {
  content: '\eb2e';
  margin-left: -1.267578125em;
  color: rgb(133, 91, 249);
}

.icon-icon-upload .path7:before {
  content: '\eb2f';
  margin-left: -1.267578125em;
  color: rgb(150, 143, 120);
}

.icon-icon-upload .path8:before {
  content: '\eb30';
  margin-left: -1.267578125em;
  color: rgb(150, 143, 120);
}

.icon-icon-upload .path9:before {
  content: '\eb31';
  margin-left: -1.267578125em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}

.icon-icon-upload .path10:before {
  content: '\eb32';
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}

.icon-icon-upload .path11:before {
  content: '\eb33';
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}

.icon-icon-upload .path12:before {
  content: '\eb34';
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}

.icon-icon-upload .path13:before {
  content: '\eb35';
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}

.icon-icon-upload .path14:before {
  content: '\eb36';
  margin-left: -1.267578125em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-link .path1:before {
  content: '\eb37';
  color: rgb(217, 217, 217);
}

.icon-icon-stickynote-insert-options-link .path2:before {
  content: '\eb38';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-link .path3:before {
  content: '\eb39';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-link .path4:before {
  content: '\eb3a';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-link .path5:before {
  content: '\eb3b';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stickynote-insert-options-link .path6:before {
  content: '\eb3c';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-trophy .path1:before {
  content: '\eb3d';
  color: rgb(242, 197, 31);
}

.icon-icon-trophy .path2:before {
  content: '\eb3e';
  margin-left: -1.015625em;
  color: rgb(239, 183, 24);
}

.icon-icon-undone-circle:before {
  content: '\eb3f';
  color: #fff;
}

.icon-icon-stars-featured-PLN .path1:before {
  content: '\eb40';
  color: rgb(0, 0, 0);
  opacity: 0.4;
}

.icon-icon-stars-featured-PLN .path2:before {
  content: '\eb41';
  margin-left: -1em;
  color: rgb(0, 0, 0);
}

.icon-icon-stars-full-with-sprites:before {
  content: '\eb42';
}

.icon-icon-like:before {
  content: '\eb43';
}

.icon-icon-3-category-types .path1:before {
  content: '\eb44';
  color: rgb(46, 171, 224);
}

.icon-icon-3-category-types .path2:before {
  content: '\eb45';
  margin-left: -1.0400390625em;
  color: rgb(133, 91, 249);
}

.icon-icon-3-category-types .path3:before {
  content: '\eb46';
  margin-left: -1.0400390625em;
  color: rgb(133, 202, 29);
}

.icon-icon-file-pdf .path1:before {
  content: '\eb47';
  color: rgb(232, 232, 223);
}

.icon-icon-file-pdf .path2:before {
  content: '\eb48';
  margin-left: -1em;
  color: rgb(203, 77, 77);
}

.icon-icon-file-pdf .path3:before {
  content: '\eb49';
  margin-left: -1em;
  color: rgb(216, 214, 203);
}

.icon-icon-notification-circle:before {
  content: '\eb4a';
  color: #dc3a2f;
}

.icon-icon-backtotop:before {
  content: '\eb4b';
}

.icon-icon-CreateGoal-magGlass-Check .path1:before {
  content: '\eb4c';
  opacity: 0.4;
}

.icon-icon-CreateGoal-magGlass-Check .path2:before {
  content: '\eb4d';
  margin-left: -0.9423828125em;
}

.icon-icon-compare-checked .path1:before {
  content: '\eb4e';
  color: rgb(255, 255, 255);
}

.icon-icon-compare-checked .path2:before {
  content: '\eb4f';
  margin-left: -1.158203125em;
  color: rgb(0, 0, 0);
}

.icon-icon-envelope:before {
  content: '\eb50';
}

.icon-clock:before {
  content: '\e913';
}

.icon-time:before {
  content: '\e913';
}

.icon-wrist-watch:before {
  content: '\e913';
}

.icon-watch:before {
  content: '\e913';
}

.icon-cabinet:before {
  content: '\e900';
}

.icon-folder1:before {
  content: '\e914';
}

.icon-checked:before {
  content: '\e90b';
}

.icon-plus:before {
  content: '\e91a';
}

.icon-minus:before {
  content: '\e91b';
}

.icon-search:before {
  content: '\e91e';
}

.icon-folder-open:before {
  content: '\e930';
}

.icon-calendar:before {
  content: '\ebc5';
}

.icon-bubble:before {
  content: '\e96b';
}

.icon-bubbles2:before {
  content: '\e96d';
}

.icon-arrow-left2:before {
  content: '\ea40';
}

.icon-share2:before {
  content: '\ea82';
}

.icon-file-pdf:before {
  content: '\eadf';
}

.icon-file-word:before {
  content: '\eae1';
}

.icon-file-excel:before {
  content: '\eae2';
}

.icon-chevron-right:before {
  content: '\e924';
}

.icon-chevron-left:before {
  content: '\e925';
}

.icon-delete:before {
  content: '\e92b';
}

.icon-delete-outline:before {
  content: '\e92c';
}

.icon-play_circle_outline:before {
  content: '\e917';
}

.icon-add:before {
  content: '\e904';
}

.icon-access_time:before {
  content: '\e932';
}

.icon-format_color_text:before {
  content: '\e90d';
}

.icon-format_list_bulleted:before {
  content: '\e90e';
}

.icon-keyboard_arrow_down:before {
  content: '\e918';
}

.icon-keyboard_arrow_right:before {
  content: '\e91c';
}

.icon-compare:before {
  content: '\e912';
}

.icon-arrow_drop_down:before {
  content: '\e90f';
}

.icon-notifications:before {
  content: '\e933';
}

.icon-dashboard:before {
  content: '\e905';
}

.icon-description:before {
  content: '\e934';
}

.icon-help:before {
  content: '\e906';
}

.icon-auto_stories:before {
  content: '\e936';
}

.icon-drive_file_move:before {
  content: '\e937';
}

.icon-pending:before {
  content: '\e938';
}

.icon-book:before {
  content: '\e939';
}

.icon-printer:before {
  content: '\e91d';
}

.icon-users:before {
  content: '\e91f';
}

.icon-user1:before {
  content: '\e93b';
}

.icon-user2:before {
  content: '\e93c';
}

.icon-camera:before {
  content: '\e920';
}

.icon-th:before {
  content: '\f00a';
}

.icon-check:before {
  content: '\f00c';
}

.icon-image1:before {
  content: '\f03e';
}

.icon-photo:before {
  content: '\f03e';
}

.icon-picture-o:before {
  content: '\f03e';
}

.icon-pencil:before {
  content: '\f040';
}

.icon-arrows:before {
  content: '\f047';
}

.icon-calendar1:before {
  content: '\f073';
}

.icon-thumb-tack:before {
  content: '\f08d';
}

.icon-bell:before {
  content: '\f0f3';
}

.icon-file-text-o:before {
  content: '\f0f6';
}

.icon-angle-double-left:before {
  content: '\f100';
}

.icon-angle-double-right:before {
  content: '\f101';
}

.icon-angle-left:before {
  content: '\f104';
}

.icon-angle-right:before {
  content: '\f105';
}

.icon-ellipsis-v:before {
  content: '\f142';
}

.icon-play-circle:before {
  content: '\f144';
}

.icon-file-text:before {
  content: '\f15c';
}

.icon-file-pdf-o:before {
  content: '\f1c1';
}

.icon-file-word-o:before {
  content: '\f1c2';
}

.icon-file-excel-o:before {
  content: '\f1c3';
}

.icon-file-image-o:before {
  content: '\f1c5';
}

.icon-file-photo-o:before {
  content: '\f1c5';
}

.icon-file-picture-o:before {
  content: '\f1c5';
}

.icon-file-audio-o:before {
  content: '\f1c7';
}

.icon-file-sound-o:before {
  content: '\f1c7';
}

.icon-file-movie-o:before {
  content: '\f1c8';
}

.icon-file-video-o:before {
  content: '\f1c8';
}

.icon-chevron-thin-left:before {
  content: '\e908';
}

.icon-chevron-thin-right:before {
  content: '\e909';
}

.icon-controller-play:before {
  content: '\e921';
}

.icon-dots-three-vertical:before {
  content: '\e93d';
}

.icon-archive:before {
  content: '\e93e';
}

.icon-circle-with-cross:before {
  content: '\e941';
}

.icon-circle-with-minus:before {
  content: '\e942';
}

.icon-circle-with-plus:before {
  content: '\e943';
}

.icon-folder2:before {
  content: '\e944';
}

.icon-link:before {
  content: '\e910';
}

.icon-badge:before {
  content: '\e946';
}

.icon-link1:before {
  content: '\e911';
}

.icon-save-disk:before {
  content: '\e949';
}

.container {
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.row {
  > * {
    padding-right: 0;
    padding-left: 0;
  }
}

.appointmentpage{
  width:1170px;
  margin:auto;

  .logo{
    text-align: center;
    img{
      padding:20px;
      height:200px;
    }
  }
  .btn{
    background-color: #568dd0;
  }
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }

  &:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  &:after {
    left: 1.5em;
  }
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.right a{
  color:#fff!important;
}
.right a:hover{
  text-decoration: none;
}

.table thead tr th{
  background-color: #6B89D8;
  color:#fff;
  border-color: transparent;
}
.table-dark{
  border-width: 0px;
}
.table-striped > tbody > tr:nth-of-type(odd) > *{
  background-color: #f2f2f2;
}
.links{
  text-align: center;
  ul{
      display:inline-block;
      
      li{
          display:inline-block;
          border:1px solid #333;
          line-height: 50px;
          margin:3px;
          width:50px;
          height:50px;
          cursor: pointer;

          &.active{
              background-color: #dc3545;
              color:#fff;
              border-color:#dc3545;
          }
      }
  }
}
.schoolform td{
  padding:10px;
}
.schoolform td select,.schoolform td input{
  padding:10px;
  width:100%;
  border-radius: 0px;
  border:1px solid #ccc;
}
.btn-edit{
  background-color: transparent!important;
  border-width: 0px;
  width:50px;
  padding: 0px;
  color: #333!important;
}

#eventSelect .mat-calendar-header{
  padding-top: 0px!important;
}
#eventSelect .mat-calendar-controls{
  margin-top: 0px!important;
  margin-bottom: 0px!important; 
}
.setbtn {
  padding-left: 20px;
  margin-left: 1rem;
  padding: 10px 23px;
  font-size: 18px;
  line-height: 23px;
  font-style: italic;
  border-radius: 10px;
  border: 0;
  cursor: pointer;
  background: #30ccae;
  color: #fff;
  width: 130px;
}